import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerManager } from 'models';
import { LayerActions } from 'state/layers';

import LayerListItem from './LayerListItem';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0),
      display: 'grid',
      padding: 0,
      gridTemplateRows: 'auto 1fr',
      height: 'calc(100vh - 300px)', // Adjust height as needed
      width: '100%',
      overflowX: 'hidden', // Scrollable if content overflows vertically
      overflowY: 'auto', // Prevent horizontal overflow
    },
    title: {
      color: theme.palette.common.neutralDark,
      width: '100%',
      textAlign: 'left',
      margin: theme.spacing(1),
    },
    subtitle: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    groupSection: {
      marginTop: 4,
      marginBottom: 4,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    accordion: {
      width: '100%',
      boxSizing: 'border-box', // Include padding and border in element's width
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    groupTitle: {
      margin: theme.spacing(1, 0),
    },
  }),
);

interface LayerListProps {
  title?: string;
  columns?: number;
  compareMapIndex?: number;
  hideBookmark?: boolean;
  displayMode?: 'accordion' | 'list';
  filterFn?: (layer: LayerManager.Layer) => boolean;
}

const LayerList: React.FunctionComponent<LayerListProps> = ({
  title,
  columns,
  filterFn,
  displayMode,
  compareMapIndex,
  hideBookmark,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const { layers } = useAppSelector((state) => state.layers);

  const mainLayers = filterFn ? layers.object?.filter(filterFn) : layers.object;

  const groupsIds = mainLayers
    ?.reduce((acc, layer) => {
      if (!acc.includes(layer.group)) return [...acc, layer.group];
      return acc;
    }, [] as string[])
    .sort(
      (a, b) =>
        LayerManager.groups.indexOf(a as LayerManager.Layer.Groups) -
        LayerManager.groups.indexOf(b as LayerManager.Layer.Groups),
    );

  const groups: Record<string, LayerManager.Layer[]> = {};
  if (mainLayers) groupsIds?.forEach((id) => (groups[id] = mainLayers.filter((l) => l.group === id)));

  useEffect(() => {
    if (layers.status === 'idle' && auth.status === 'finished') dispatch(LayerActions.getLayers());
  }, [layers.status, auth.status, dispatch]);

  useEffect(() => {
    if (auth.status === 'finished') dispatch(LayerActions.getLayers());
    dispatch(LayerActions.getBookmarkedLayers());
  }, [dispatch]);

  const mapFn = (layer: LayerManager.Layer) => (
    <LayerListItem
      key={layer.id}
      layer={layer}
      compareMapIndex={compareMapIndex}
      hideBookmark={hideBookmark}
      style={{
        breakInside: 'avoid',
        pageBreakInside: 'avoid',
      }}
    />
  );

  return (
    <div className={classes.root}>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}
      <div style={{ columns: columns ?? 1 }}>
        {groupsIds?.map((group) => (
          <div key={group} className={classes.groupSection}>
            {displayMode === 'list' ? (
              <>
                <Typography className={classes.groupTitle} variant="subtitle2">
                  {group}
                </Typography>
                {groups[group].map(mapFn)}
              </>
            ) : (
              <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className={classes.subtitle}>{group}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>{groups[group].map(mapFn)}</AccordionDetails>
              </Accordion>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

LayerList.displayName = 'LayerList';
export default LayerList;

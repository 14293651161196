export enum Tabs {
  AllLayers = 'alllayers',
  Bookmarks = 'bookmarks',
  Incidents = 'incidents',
  Export = 'export',
}

export enum Overlays {
  None = '',
  Loading = 'loading',
  Weather = 'weather',
  WeatherStation = 'weatherstation',
  Incident = 'incident',
}

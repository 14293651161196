/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Button, Dialog, DialogContent, Typography, Box } from '@material-ui/core';
import { Add, Remove, KeyboardArrowLeft, Layers, GetApp, Close, Edit } from '@material-ui/icons';

import {
  MapCommand,
  MapMove,
  ZoomToBounds,
  AddScaleLine,
  MouseCoords,
  AddWmsTileLayer,
  AddWmsMvtLayer,
  GetMapBounds,
} from 'components/map/MapCommands';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerActions } from 'state/layers';
import { AnalyticsActions } from 'state/analytics';
import {
  Map,
  FloatingMapButton,
  LayerList,
  Legend,
  MapOverlay,
  ComparisonExportManager,
  Timeslider,
  BasemapsBoundariesDialog,
} from 'components';
import { LayerManager } from 'models';
import { getUserState, hasGroup } from 'utils';
import LayerControls from 'components/map/LayerControls';
import config from 'config';
import generateOffsetsMatrixHelper from './fdv_viewer_subcomponents/helper_functions/generate_offsets_map';
import { layersWithToggleableLabels } from 'models/layer';

const initOpacity = 65;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
    },
    header: {
      padding: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto auto auto auto',
    },
    headerBtn: {
      color: theme.palette.common.black70,
    },
    mapsContainer: {
      paddingLeft: theme.spacing(1),
    },
    mapContainer: {
      height: '100%',
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      position: 'relative',
    },
    footer: {
      height: 150,
      padding: theme.spacing(2),
    },
    layersFAB: {
      borderRadius: 4,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
    title: {
      color: theme.palette.common.neutralDark,
    },
    layerControls: {
      borderRadius: 4,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
  }),
);

function FDVMapCompare() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mapRefs = [useRef<any>(null), useRef<any>(null), useRef<any>(null), useRef<any>(null)];

  const { auth } = useAppSelector((state) => state);
  const {
    layers,
    selectedBoundary,
    selectedCompareLayers,
    selectedLayers,
    selectedBaseMap,
    viewBounds,
    selectedTime,
    compareViewsCount,
  } = useAppSelector((state) => state.layers);

  const selectedRasterLayerList = selectedCompareLayers.map((id) => layers.object?.find((l) => l.id === id) ?? null);

  const childLayerList = selectedRasterLayerList.map((selectedRasterLayer) =>
    selectedRasterLayer && selectedRasterLayer.childLayer
      ? layers.object?.find((l) => l.id === selectedRasterLayer.childLayer)
      : null,
  );

  const totalRasterLayerList = [
    ...selectedRasterLayerList.slice(0, compareViewsCount),
    ...childLayerList.slice(0, compareViewsCount),
  ].filter((x) => x != null) as LayerManager.Layer[];

  // This mimics the offsets that the time slider would apply to the appropriate layers in order to render the time slider correctly
  const offsetsMap = generateOffsetsMatrixHelper({
    layers: totalRasterLayerList,
  });

  const jd = getUserState(auth.object);

  let initBounds = viewBounds;
  if (!initBounds && jd) {
    initBounds = config.jurisdictionBounds[jd];
  }

  const [initialZoom, setInitialZoom] = useState(false);

  const [layerManagerOpen, setLayerManagerOpen] = useState(false);
  const [generalManagerOpen, setGeneralManagerOpen] = useState(false);
  const [exportManagerOpen, setExportManagerOpen] = useState(false);

  const [sentInitialLoad, setSentInitialLoad] = useState(false);

  const [selectedLayerManager, setSelectedLayerManager] = useState<null | number>(null);

  const [mapMoveCommand] = useState<MapMove>(new MapMove());

  const [mapDispatches, setMapDispatches] = useState<{ dispatch: (command: MapCommand) => void }[]>([]);
  const [boundaryLayerCommands, setBoundaryLayerCommands] = useState<AddWmsMvtLayer[]>([]);
  const [boundaryLabelLayerCommands, setBoundaryLabelLayerCommands] = useState<AddWmsMvtLayer[]>([]);
  const [incidentsLayerCommands, setIncidentsLayerCommands] = useState<AddWmsTileLayer[]>([]);
  const [weatherStationsLayerCommands, setWeatherStationsLayerCommands] = useState<AddWmsTileLayer[]>([]);
  const [rasterLayerCommands, setRasterLayerCommands] = useState<AddWmsTileLayer[]>([]);
  const [childLayerCommands, setChildLayerCommands] = useState<AddWmsTileLayer[]>([]);

  const handleAddView = () => {
    dispatch(LayerActions.setCompareViewsCount(compareViewsCount + 1));
    setTimeout(() => mapMoveCommand.resize(), 100);
  };

  const handleRemoveView = () => {
    dispatch(LayerActions.setCompareViewsCount(compareViewsCount - 1));
    setTimeout(() => mapMoveCommand.resize(), 100);
  };

  const handleTimeChange = (layerDates: Record<string, Date | null>) => {
    selectedRasterLayerList.forEach((layer, idx) => {
      if (layer && layerDates[layer.id]) {
        let layerDate = layerDates[layer.id];

        // Get the offset in hours (and subtract that from the layer date)
        if (offsetsMap !== undefined && layerDate !== null) {
          const offsetMs = offsetsMap[layer.id] * (1000 * 60 * 60);
          layerDate = new Date(layerDate.getTime() - offsetMs);
        }

        rasterLayerCommands[idx]?.updateDate(layerDate, layer.lastUpdated != null ? new Date(layer.lastUpdated) : null);
      } else if (layer?.hoursPerStep === 0) {
        rasterLayerCommands[idx]?.updateDate(
          layer.timeSteps?.[0],
          layer.lastUpdated != null ? new Date(layer.lastUpdated) : null,
        );
      }
    });

    childLayerList.forEach((layer, idx) => {
      if (layer && layerDates[layer.id]) {
        childLayerCommands[idx]?.updateDate(
          layerDates[layer.id],
          layer.lastUpdated != null ? new Date(layer.lastUpdated) : null,
        );
      } else if (layer?.hoursPerStep === 0) {
        childLayerCommands[idx]?.updateDate(
          layer.timeSteps?.[0],
          layer.lastUpdated != null ? new Date(layer.lastUpdated) : null,
        );
      }
    });
  };

  const renderRow = (offset: number, count: number) => {
    return [...new Array(count)].map((_, i) => {
      const index = offset + i;

      const handleMapRegistration = (mapDispatcher: (command: MapCommand) => void) => {
        setMapDispatches((prev) => {
          const newState = [...prev];
          newState[index] = { dispatch: mapDispatcher };
          return newState;
        });

        mapDispatcher(mapMoveCommand);
        mapDispatcher(new AddScaleLine());
        mapDispatcher(new MouseCoords());

        const mb = new GetMapBounds((bounds) => dispatch(LayerActions.setViewBounds(bounds)));
        mapDispatcher(mb);

        const rasterLayer = new AddWmsTileLayer({
          layerName: null,
          auth: auth.object,
          opacity: initOpacity,
          date: selectedTime,
        });
        mapDispatcher(rasterLayer);
        setRasterLayerCommands((prevCmds) => {
          const newLayerCommands = [...prevCmds];
          newLayerCommands[index] = rasterLayer;
          return newLayerCommands;
        });

        const childRasterLayer = new AddWmsTileLayer({
          layerName: null,
          auth: auth.object,
          opacity: initOpacity,
          date: selectedTime,
        });
        mapDispatcher(childRasterLayer);
        setChildLayerCommands((prevCmds) => {
          const newLayerCommands = [...prevCmds];
          newLayerCommands[index] = childRasterLayer;
          return newLayerCommands;
        });

        /**
         * Register the boundary label layer, associated with the underlying boundary layer
         * This is a vector layer and the label styles are controlled in the AddWmsMvtLayer class
         */
        const boundaryLabelLayer = new AddWmsMvtLayer({
          layerName: null,
          labelField: null,
          auth: auth.object,
          isLabelOn: true,
        });
        setBoundaryLabelLayerCommands((prevCmds) => {
          const newLayerCommands = [...prevCmds];
          newLayerCommands[index] = boundaryLabelLayer;
          return newLayerCommands;
        });
        mapDispatcher(boundaryLabelLayer);

        /**
         * Always set the isLabelOn to false on layer creation and never change it afterwards
         */
        const boundaryLayer = new AddWmsMvtLayer({
          layerName: null,
          labelField: null,
          auth: auth.object,
          isLabelOn: false,
        });
        setBoundaryLayerCommands((prevCmds) => {
          const newLayerCommands = [...prevCmds];
          newLayerCommands[index] = boundaryLayer;
          return newLayerCommands;
        });
        mapDispatcher(boundaryLayer);

        const weatherStationLayer = new AddWmsTileLayer({
          layerName:
            selectedLayers && selectedLayers.indexOf('WeatherStations') > -1
              ? LayerManager.findLayer('WeatherStations', LayerManager.layerData)?.serviceName ?? null
              : null,
          auth: auth.object,
        });
        mapDispatcher(weatherStationLayer);
        setWeatherStationsLayerCommands((prevCmds) => {
          const newLayerCommands = [...prevCmds];
          newLayerCommands[index] = weatherStationLayer;
          return newLayerCommands;
        });

        const incidentsLayer = new AddWmsTileLayer({
          layerName:
            selectedLayers && selectedLayers.indexOf('Incidents') > -1
              ? LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName ?? null
              : null,
          auth: auth.object,
        });
        mapDispatcher(incidentsLayer);
        setIncidentsLayerCommands((prevCmds) => {
          const newLayerCommands = [...prevCmds];
          newLayerCommands[index] = incidentsLayer;
          return newLayerCommands;
        });
      };

      const onOpacityUpdate = (value: number) => {
        rasterLayerCommands[index]?.setOpacity(value);
        childLayerCommands[index]?.setOpacity(value);
      };

      const onLabelStateUpdate = (value: boolean) => boundaryLabelLayerCommands[index]?.setLabelState(value);

      const btnText = selectedRasterLayerList[index]?.name ?? 'Set layer';
      let btnFontSize = 14;
      if (btnText.length > 14) btnFontSize = 12;
      if (btnText.length > 18) btnFontSize = 11;

      return (
        <Grid item xs key={index} className={classes.mapContainer}>
          <Map
            key={index}
            mapRef={mapRefs[index]}
            featureLayers={[]}
            basemap={selectedBaseMap}
            shouldDisplay
            hideStandardPopup
            registerMapCommand={handleMapRegistration}
          >
            <Box display="flex" flexDirection="row" style={{ position: 'absolute', left: '65px', top: '10px' }}>
              <FloatingMapButton
                width="max-content"
                height="30px"
                className={classes.layersFAB}
                flex={true}
                buttonStyle={{
                  color: theme.palette.common.neutralDark,
                  fontSize: btnFontSize,
                }}
                muiButton
                onClick={() => {
                  setSelectedLayerManager(index);
                  setLayerManagerOpen(true);
                }}
              >
                {btnText} <Edit />
              </FloatingMapButton>

              <MapOverlay
                opacity={1.0}
                flex={true}
                style={{
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
                }}
                className={classes.layerControls}
              >
                <LayerControls
                  buttonStyle={{
                    width: '165px',
                    height: '30px',
                    borderRadius: '120px',
                  }}
                  onOpacityUpdate={onOpacityUpdate}
                  initOpacity={initOpacity}
                  initLabelState={boundaryLabelLayerCommands[index]?.getLabelState()}
                  onLabelStateUpdate={onLabelStateUpdate}
                  isLabelToggleDisabled={selectedBoundary ? !layersWithToggleableLabels.has(selectedBoundary) : true}
                />
              </MapOverlay>
            </Box>

            <MapOverlay
              left="default"
              opacity={1.0}
              top="10px"
              style={{
                right: '10px',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
              }}
            >
              <Legend layer={selectedRasterLayerList[index]} maxHeight={compareViewsCount > 2 ? 200 : 450} />
            </MapOverlay>
          </Map>
        </Grid>
      );
    });
  };

  const handleModalClose = () => {
    setLayerManagerOpen(false);
    setSelectedLayerManager(null);
  };

  const renderViews = () => {
    if (compareViewsCount <= 2)
      return (
        <Grid container style={{ height: '100%' }}>
          {renderRow(0, compareViewsCount)}
        </Grid>
      );

    return (
      <>
        <Grid container style={{ height: '50%' }}>
          {renderRow(0, 2)}
        </Grid>
        <Grid container style={{ height: '50%' }}>
          {renderRow(2, compareViewsCount - 2)}
        </Grid>
      </>
    );
  };

  useEffect(() => {
    if (layers.status === 'idle' && auth.status === 'finished') dispatch(LayerActions.getLayers());

    if (!sentInitialLoad && layers.status === 'finished' && auth.status === 'finished') {
      selectedLayers?.forEach((id) => {
        const l = LayerManager.findLayer(id, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      });

      selectedRasterLayerList?.forEach((rasterLayer) => {
        if (rasterLayer) dispatch(AnalyticsActions.postLayerView(rasterLayer));
      });

      childLayerList?.forEach((childLayer) => {
        if (childLayer) dispatch(AnalyticsActions.postLayerView(childLayer));
      });

      if (selectedBoundary) {
        const l = LayerManager.findLayer(selectedBoundary, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      }

      if (selectedBaseMap) {
        const l = LayerManager.findLayer(selectedBaseMap, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      }

      setSentInitialLoad(true);
    }

    // regularly update layers every 5 minutes
    const interval = setInterval(() => {
      if ((layers.status === 'idle' || layers.status === 'finished') && auth.status === 'finished')
        dispatch(LayerActions.getLayers());
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, layers.status, auth.status]);

  /** Update both boundary layer and its label layer when boundary layer changes */
  useEffect(() => {
    const selectedBoundaryLayer = layers.object?.find((l) => l.id === selectedBoundary);

    boundaryLabelLayerCommands.forEach((boundaryLabelLayerCommand) => {
      // Update the labels of the associated boundary label layer
      boundaryLabelLayerCommand?.update({
        layerName: selectedBoundaryLayer?.labelLayerServiceName ? selectedBoundaryLayer.labelLayerServiceName : null,
        labelField: selectedBoundaryLayer?.labelLayerLabelField ? selectedBoundaryLayer.labelLayerLabelField : null,
        auth: auth.object,
      });
    });

    boundaryLayerCommands.forEach((boundaryLayerCommand) => {
      /**
       * Update the boundary layer
       * MvtLabelField still needs to be set here, although for the boundary layers, this label is never shown.
       * In FSE, there's is a highlight feature which compares the selected area name with the label names from the boundary layers and highlights the boundary of that particular area. It's essential to have this label enabled and served via AddWmsMvtLayer
       */
      boundaryLayerCommand?.update({
        layerName: selectedBoundaryLayer != null ? selectedBoundaryLayer.serviceName : null,
        labelField: selectedBoundaryLayer != null ? selectedBoundaryLayer.mvtLabelField : null,
        auth: auth.object,
      });
    });
  }, [selectedBoundary, boundaryLayerCommands, layers.object, auth.object]);

  useEffect(() => {
    // calculate the time parameter based on latest layer
    // start date in selectedCompareLayers
    // Should be the list of only the layers selected
    const latestStart = LayerManager.getLatestLayerStartDate(selectedRasterLayerList ?? undefined);
    rasterLayerCommands.forEach((rasterLayerCommand, idx) => {
      const selectedLayer = layers.object?.find((l) => l.id === selectedCompareLayers[idx]);
      let stepTime = null;
      if (selectedLayer?.id) {
        stepTime = LayerManager.getStepTime(layers.object || undefined, selectedLayer?.id, selectedTime ?? latestStart);
      }
      rasterLayerCommand?.update({
        layerName: selectedLayer != null ? selectedLayer.serviceName : null,
        auth: auth.object,
        date: stepTime != null ? stepTime : null,
      });

      if (selectedLayer?.childLayer) {
        const selectedChildLayer = layers.object?.find((l) => l.id === selectedLayer.childLayer);
        childLayerCommands[idx]?.update({
          layerName: selectedChildLayer != null ? selectedChildLayer.serviceName : null,
          auth: auth.object,
          date: stepTime != null ? stepTime : null,
        });
      } else {
        childLayerCommands[idx]?.update({
          layerName: null,
          auth: auth.object,
        });
      }
    });
  }, [selectedCompareLayers, rasterLayerCommands, childLayerCommands, layers.object, auth.object]);

  useEffect(() => {
    incidentsLayerCommands.forEach((incidentsLayerCommand) => {
      if (selectedLayers && selectedLayers.indexOf('Incidents') > -1) {
        incidentsLayerCommand?.update({
          layerName: LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName ?? null,
          auth: auth.object,
        });
      } else {
        incidentsLayerCommand?.update({
          layerName: null,
          auth: auth.object,
        });
      }
    });

    weatherStationsLayerCommands.forEach((weatherStationsLayerCommand) => {
      if (selectedLayers && selectedLayers.indexOf('WeatherStations') > -1) {
        weatherStationsLayerCommand?.update({
          layerName: LayerManager.findLayer('WeatherStations', LayerManager.layerData)?.serviceName ?? null,
          auth: auth.object,
        });
      } else {
        weatherStationsLayerCommand?.update({
          layerName: null,
          auth: auth.object,
        });
      }
    });
  }, [selectedLayers, auth.object]);

  useEffect(() => {
    // will update incidents every 60 seconds
    const interval = setInterval(() => incidentsLayerCommands.forEach((cmd) => cmd?.refresh()), 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentsLayerCommands]);

  useEffect(() => {
    if (!initialZoom && mapDispatches[0]) {
      if (initBounds) mapDispatches[0].dispatch(new ZoomToBounds(initBounds));
      setInitialZoom(true);
    }
  }, [mapDispatches]);

  return (
    <>
      <style>
        {`

        .ol-zoom {
          display: table;
          left: 10px;
          top: 10px;
        }
        
        .ol-zoom-extent {
          left: 10px;
          top: 70px;
        }

        `}
      </style>
      <div className={classes.root}>
        <div className={classes.header}>
          <Button variant="outlined" className={classes.headerBtn} onClick={() => navigate('/')}>
            <KeyboardArrowLeft /> Back to map
          </Button>
          <div />
          <Button variant="outlined" className={classes.headerBtn} onClick={() => setExportManagerOpen(true)}>
            <GetApp /> Export Maps
          </Button>
          <Button
            variant="outlined"
            style={{ marginLeft: theme.spacing(1) }}
            className={classes.headerBtn}
            onClick={() => setGeneralManagerOpen(true)}
          >
            <Layers /> Layer Settings
          </Button>
          <Button
            disabled={compareViewsCount === 2}
            style={{ marginLeft: theme.spacing(2) }}
            variant="outlined"
            className={classes.headerBtn}
            onClick={handleRemoveView}
          >
            <Remove /> Remove a view
          </Button>
          <Button
            disabled={compareViewsCount >= 4}
            style={{ marginLeft: theme.spacing(1) }}
            variant="outlined"
            className={classes.headerBtn}
            onClick={handleAddView}
          >
            <Add /> Add a view
          </Button>
        </div>
        <div className={classes.mapsContainer}>{renderViews()}</div>
        <Timeslider
          layers={totalRasterLayerList}
          onTimeChange={(_date, layerDates, _initial) => handleTimeChange(layerDates)}
          viewCount={compareViewsCount}
          offsetsMap={offsetsMap}
        />
        <Dialog
          open={layerManagerOpen}
          onClose={handleModalClose}
          fullWidth
          maxWidth="md"
          aria-labelledby="Layer Manager"
          aria-describedby="Manages the layers for the selected map"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
            <Typography variant="h6" className={classes.title}>
              Base Layers
            </Typography>
            <Close style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={handleModalClose} />
          </div>
          <DialogContent>
            {selectedLayerManager != null && (
              <LayerList
                compareMapIndex={selectedLayerManager}
                columns={2}
                hideBookmark
                filterFn={(l) =>
                  !l.isBaseMap &&
                  !l.isBoundary &&
                  !l.isHidden &&
                  (!l.isPrivileged || (l.isPrivileged && hasGroup('fdv-elevated', auth)))
                }
                displayMode="list"
              />
            )}
          </DialogContent>
        </Dialog>
        <BasemapsBoundariesDialog open={generalManagerOpen} setOpen={setGeneralManagerOpen} />
        <Dialog
          open={exportManagerOpen}
          onClose={() => setExportManagerOpen(false)}
          fullWidth
          maxWidth="md"
          aria-labelledby="Export Manager"
          aria-describedby="Manages the export of pdfs"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
            <Typography variant="h6" className={classes.title}>
              Export to PDF
            </Typography>
            <Close style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setExportManagerOpen(false)} />
          </div>
          <DialogContent>
            <ComparisonExportManager mapRefs={mapRefs} viewCount={compareViewsCount} selectedDate={selectedTime} />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default FDVMapCompare;

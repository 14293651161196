import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Theme,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core';
import { Refresh, Layers, LayersClear, ExpandMore, Cancel } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { useAppSelector, useAppDispatch } from 'hooks';
import { IncidentActions } from 'state/incidents';
import { LayerActions } from 'state/layers';
import { Common, IncidentManager, jurisdictions } from 'models';
import { IncidentListItem, CustomDateTimePicker } from 'components';

import { getUserState, toTimeDDMonYearFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0),
      padding: 0,
      height: 'calc(100vh - 300px)',
      width: '100%',
      overflowX: 'hidden',
      overflowY: 'auto', // Scroll for vertical overflow
    },
    title: {
      color: theme.palette.common.neutralDark,
      width: '100%',
      textAlign: 'left',
      padding: '8px',
    },
    subtitle: {
      color: theme.palette.common.neutralDark,
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
    closeIcon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    filterSection: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto auto',
    },
    filterSectionVertical: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      display: 'grid',
      gridAutoFlow: 'row',
      gridTemplateRows: '1fr auto auto',
    },
    dateTimePickerText: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.common.black70,
    },
    searchText: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    accordion: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    accordionDetails: {
      display: 'grid',
    },
    toggleButton: {
      width: 50,
    },
  }),
);

interface IncidentsManagerProps {
  onRefresh?: () => void;
  onZoomToIncident?: (incident: IncidentManager.Incident) => void;
  goToIncident?: string | null;
}

const IncidentsManager: React.FunctionComponent<IncidentsManagerProps> = ({
  onRefresh,
  goToIncident,
  onZoomToIncident,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { incidents, auth } = useAppSelector((state) => state);
  const { selectedLayers } = useAppSelector((state) => state.layers);

  const [userState, setuserState] = useState<Common.Jurisdictions | null>(getUserState(auth.object));
  const [stateFilter, setStateFilter] = useState<Common.Jurisdictions | null>(userState);

  const [filterCreatedBefore, setFilterCreatedBefore] = useState<Date | null>(null);
  const [filterCreatedAfter, setFilterCreatedAfter] = useState<Date | null>(null);

  const [filterUpdatedBefore, setFilterUpdatedBefore] = useState<Date | null>(null);
  const [filterUpdatedAfter, setFilterUpdatedAfter] = useState<Date | null>(null);

  const [warningCategory, setWarningCategory] = useState<string | null>(null);
  const [incidentsEnabled, setIncidentsEnabled] = useState(selectedLayers && selectedLayers.indexOf('Incidents') > -1);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (goToIncident === searchText) {
      setSearchText('');
    } else if (goToIncident) {
      setStateFilter(null);
      setWarningCategory(null);
      setSearchText(goToIncident);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToIncident]);

  useEffect(() => {
    setIncidentsEnabled(selectedLayers && selectedLayers.indexOf('Incidents') > -1);
  }, [selectedLayers]);

  const enableIncidents = () => {
    if (selectedLayers && selectedLayers.indexOf('Incidents') === -1)
      dispatch(LayerActions.setSelectedLayers([...selectedLayers, 'Incidents']));
  };

  const disableIncidents = () => {
    if (selectedLayers && selectedLayers.indexOf('Incidents') > -1)
      dispatch(LayerActions.setSelectedLayers(selectedLayers.filter((i) => i !== 'Incidents')));
  };

  useEffect(() => {
    // will update incidents every 60 seconds
    const interval = setInterval(() => dispatch(IncidentActions.getIncidents()), 60000);
    dispatch(IncidentActions.getIncidents());

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (auth.object) setuserState(getUserState(auth.object));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, auth.status]);

  const filterFn = (incident: IncidentManager.Incident): boolean => {
    if (stateFilter && stateFilter !== incident.jurisdiction) return false;

    if (warningCategory && warningCategory.toLowerCase() !== incident.warningCategory.toLowerCase()) return false;

    if (filterCreatedBefore && (incident.notified == null || filterCreatedBefore < incident.notified)) return false;
    if (filterCreatedAfter && (incident.notified == null || filterCreatedAfter > incident.notified)) return false;

    if (filterUpdatedBefore && (incident.updateDate == null || filterUpdatedBefore < incident.updateDate)) return false;
    if (filterUpdatedAfter && (incident.updateDate == null || filterUpdatedAfter > incident.updateDate)) return false;

    const lcSearchText = searchText?.toLowerCase();

    if (
      lcSearchText &&
      !(
        incident.nativeId?.toLowerCase().includes(lcSearchText) ||
        incident.title?.toLowerCase().includes(lcSearchText) ||
        incident.type?.toLowerCase().includes(lcSearchText) ||
        incident.warningCategory?.toLowerCase().includes(lcSearchText) ||
        incident.fireType?.toLowerCase().includes(lcSearchText) ||
        incident.incidentType?.toLowerCase().includes(lcSearchText) ||
        incident.status?.toLowerCase().includes(lcSearchText) ||
        incident.locationDescription?.toLowerCase().includes(lcSearchText) ||
        incident.lga?.toLowerCase().includes(lcSearchText)
      )
    )
      return false;

    return true;
  };

  const incidentItems = incidents.all.object
    ?.filter(filterFn)
    .map((incident) => <IncidentListItem key={incident.id} incident={incident} onZoomToClick={onZoomToIncident} />);
  const midpoint = Math.ceil(jurisdictions.length / 2);
  const row1 = jurisdictions.slice(0, midpoint);
  const row2 = jurisdictions.slice(midpoint);

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Incidents
      </Typography>
      {incidents.all.status === 'loading' && (
        <CircularProgress style={{ margin: theme.spacing(1) }} size={20} aria-valuetext="loading" />
      )}

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', height: '37px' }}>
        {incidentsEnabled ? (
          <Button onClick={() => disableIncidents()}>
            <LayersClear className={classes.icon} /> Hide Incidents
          </Button>
        ) : (
          <Button onClick={() => enableIncidents()}>
            <Layers className={classes.icon} /> Show Incidents
          </Button>
        )}

        <Button
          onClick={() => {
            if (onRefresh) onRefresh();
            dispatch(IncidentActions.getIncidents());
          }}
        >
          <Refresh className={classes.icon} /> Refresh
        </Button>
      </div>

      <div className={classes.searchText}>
        <TextField
          value={searchText}
          onChange={(event: any) => setSearchText(event?.target?.value)}
          variant="outlined"
          placeholder="Search Incidents"
          fullWidth
        />
      </div>

      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6" className={classes.subtitle}>
            Filters &middot; {incidentItems?.length ?? 'No'} results
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <div className={classes.filterSectionVertical}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Jurisdiction:
            </Typography>
            <ToggleButtonGroup
              value={stateFilter}
              exclusive
              onChange={(_, value) => setStateFilter(value as Common.Jurisdictions | null)}
              aria-label="filter incidents by jurisdiction"
            >
              {row1.map((jurisdiction) => (
                <ToggleButton
                  key={jurisdiction}
                  value={jurisdiction}
                  aria-label={`view ${jurisdiction}`}
                  className={classes.toggleButton}
                >
                  {jurisdiction.toUpperCase()}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <ToggleButtonGroup
              value={stateFilter}
              exclusive
              onChange={(_, value) => setStateFilter(value as Common.Jurisdictions | null)}
              aria-label="filter incidents by jurisdiction"
            >
              {row2.map((jurisdiction) => (
                <ToggleButton
                  key={jurisdiction}
                  value={jurisdiction}
                  aria-label={`view ${jurisdiction}`}
                  className={classes.toggleButton}
                >
                  {jurisdiction.toUpperCase()}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
          <div className={classes.filterSectionVertical}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Warning Category:
            </Typography>
            <ToggleButtonGroup
              value={warningCategory}
              exclusive
              onChange={(_, value) => {
                setWarningCategory(value as string);
              }}
              aria-label="filter incidents by juristdiction"
            >
              <ToggleButton value="Advice" aria-label="view all">
                Advice
              </ToggleButton>
              <ToggleButton value="WatchAndAct" aria-label={`view ${userState}`}>
                Watch &amp; Act
              </ToggleButton>
              <ToggleButton value="EmergencyWarning" aria-label={`view ${userState}`}>
                Emergency
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className={classes.filterSection}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Created After:
            </Typography>
            <CustomDateTimePicker
              className={classes.dateTimePickerText}
              text={filterCreatedAfter ? toTimeDDMonYearFormat(filterCreatedAfter) : 'Click to Set'}
              onChange={(newDate) => setFilterCreatedAfter(newDate)}
            />
            {filterCreatedAfter && <Cancel className={classes.closeIcon} onClick={() => setFilterCreatedAfter(null)} />}
          </div>
          <div className={classes.filterSection}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Created Before:
            </Typography>
            <CustomDateTimePicker
              className={classes.dateTimePickerText}
              text={filterCreatedBefore ? toTimeDDMonYearFormat(filterCreatedBefore) : 'Click to Set'}
              onChange={(newDate) => setFilterCreatedBefore(newDate)}
            />
            {filterCreatedBefore && (
              <Cancel className={classes.closeIcon} onClick={() => setFilterCreatedBefore(null)} />
            )}
          </div>
          <div className={classes.filterSection}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Last Updated After:
            </Typography>
            <CustomDateTimePicker
              className={classes.dateTimePickerText}
              text={filterUpdatedAfter ? toTimeDDMonYearFormat(filterUpdatedAfter) : 'Click to Set'}
              onChange={(newDate) => setFilterUpdatedAfter(newDate)}
            />
            {filterUpdatedAfter && <Cancel className={classes.closeIcon} onClick={() => setFilterUpdatedAfter(null)} />}
          </div>
          <div className={classes.filterSection}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Last Updated Before:
            </Typography>
            <CustomDateTimePicker
              className={classes.dateTimePickerText}
              text={filterUpdatedBefore ? toTimeDDMonYearFormat(filterUpdatedBefore) : 'Click to Set'}
              onChange={(newDate) => setFilterUpdatedBefore(newDate)}
            />
            {filterUpdatedBefore && (
              <Cancel className={classes.closeIcon} onClick={() => setFilterUpdatedBefore(null)} />
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>{incidentItems}</div>
    </div>
  );
};

IncidentsManager.displayName = 'IncidentsManager';
export default IncidentsManager;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Accordion, AccordionDetails, AccordionSummary, Divider, Button } from '@material-ui/core';
import { ExpandMore, Add } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerManager, Common } from 'models';
import { LayerActions } from 'state/layers';
import { MapCommand } from 'components/map/MapCommands';

import { setExtentToLocalStorage, getExtentsFromLocalStorage } from 'utils/layerMiddleware';

import LayerListItem from './LayerListItem';
import ExtentBookmark from './ExtentBookmark';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0),
      height: 'calc(100vh - 340px)', // Adjust height as needed
    },
    title: {
      color: theme.palette.common.neutralDark,
      width: '100%',
      textAlign: 'left',
      margin: theme.spacing(1),
    },
    subtitle: {
      color: theme.palette.common.neutralDark,
    },
    groupSection: {
      marginTop: 4,
      marginBottom: 4,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    accordion: {
      width: '100%',
      boxSizing: 'border-box', // Include padding and border in element's width
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
    },
    headerBtn: {
      color: theme.palette.common.black70,
    },
  }),
);

type Bounds = Common.Bounds | null;

interface BookmarkedLayersProps {
  getBounds: () => Common.Bounds | null;
  mapDispatch?: {
    dispatch: (command: MapCommand) => void;
  };
}

const Bookmarks: React.FunctionComponent<BookmarkedLayersProps> = ({ getBounds, mapDispatch }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector((state) => state);

  // index 0: layer bookmarks, index 1: extent bookmarks
  // layer bookmarks will be open by default
  const bookmarkTypes = ['Layer Bookmarks', 'Extent Bookmarks'];

  const { bookmarkedLayers, bookmarkUpdate } = useAppSelector((state) => state.layers);

  const mainBookmarkLayers = bookmarkedLayers.object?.filter((l) => l.isMainLayer || l.isBaseMap || l.isBoundary);
  const otherBookmarkLayers = bookmarkedLayers.object?.filter((l) => !(l.isMainLayer || l.isBaseMap || l.isBoundary));

  const [storedExtents, setStoredExtents] = useState(getExtentsFromLocalStorage());

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (auth.status === 'finished') {
      if (bookmarkUpdate.status === 'finished' || bookmarkUpdate.status === 'idle')
        dispatch(LayerActions.getBookmarkedLayers());

      // regularly update layers every 2 minutes
      interval = setInterval(() => {
        if (bookmarkUpdate.status === 'idle' || bookmarkUpdate.status === 'finished')
          dispatch(LayerActions.getBookmarkedLayers());
      }, 5 * 60 * 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [bookmarkUpdate.status, auth?.status, dispatch]);

  const layerMapFn = (layer: LayerManager.Layer) => {
    return <LayerListItem key={layer.id} layer={layer} />;
  };

  // Function generates unique names for new extent bookamrks by incrementing a counter
  const generateUniqueExtentName = (baseName: string, existingExtents: { name: string }[]): string => {
    let counter = existingExtents.length + 1;
    let name = `${baseName} ${counter}`;
    const isNameTaken = (extentName: string) => existingExtents.some((extent) => extent.name === extentName);
    while (isNameTaken(name)) {
      counter += 1;
      name = `${baseName} ${counter}`;
    }
    return name;
  };

  const handleNewExtent = () => {
    const baseName = 'Extent';
    const name = generateUniqueExtentName(baseName, storedExtents);

    try {
      const currentMapBounds = getBounds();
      if (currentMapBounds == null) throw new Error('Current map bounds are null or undefined');

      const today = new Date().toISOString();
      const newExtents = [...storedExtents, { name, extent: currentMapBounds, date: today }];

      setStoredExtents(newExtents);
      setExtentToLocalStorage(name, currentMapBounds, today);
    } catch (error) {
      console.error('Failed to add new extent bookmark:', error);
    }
  };

  const handleLayerBookmarks = () => {
    const mainLayers = mainBookmarkLayers?.map(layerMapFn) || [];
    const otherLayers = otherBookmarkLayers?.map(layerMapFn) || [];

    if (mainLayers.length === 0 && otherLayers.length === 0) {
      return 'No layer bookmarks saved';
    }

    return (
      <>
        {mainLayers}
        {mainLayers.length > 0 && otherLayers.length > 0 && <Divider />}
        {otherLayers}
      </>
    );
  };

  const handleExtentBookmarks = () => {
    return (
      <>
        {storedExtents?.map((extent: { name: string; extent: Bounds; date: string }) => (
          <ExtentBookmark
            key={extent.name}
            name={extent.name}
            bounds={extent.extent}
            date={extent.date}
            storedExtents={storedExtents}
            setStoredExtents={setStoredExtents}
            mapDispatch={mapDispatch}
          />
        ))}
        <Button variant="outlined" className={classes.headerBtn} onClick={() => handleNewExtent()}>
          <Add /> Add Extent Bookmark
        </Button>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {'Bookmarks'}
      </Typography>
      {bookmarkTypes.map((type, index) => (
        <div key={type} className={classes.groupSection}>
          <Accordion className={classes.accordion} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography className={classes.subtitle}>{type}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {index === 0 ? handleLayerBookmarks() : handleExtentBookmarks()}
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

Bookmarks.displayName = 'Bookmarks';
export default Bookmarks;

import { createAction, createReducer } from '@reduxjs/toolkit';

import { Common, LayerManager, WmsManager, Loadable } from 'models';
import { makeThunkFromAPICall } from 'state';
import { getLayerStateFromLocalStorage } from 'utils/layerMiddleware';

export type LayerState = {
  layers: Loadable<LayerManager.Layer[]>;
  legends: Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>;
  bookmarkedLayers: Loadable<LayerManager.Layer[]>;
  bookmarkUpdate: Loadable<null>;
  selectedMainLayer: LayerManager.Layer.LayerIds | null;
  selectedCompareLayers: (LayerManager.Layer.LayerIds | null)[];
  selectedBaseMap: LayerManager.Layer.LayerIds | null;
  selectedBoundary: LayerManager.Layer.LayerIds | null;
  selectedLayers: LayerManager.Layer.LayerIds[] | null;
  viewBounds: Common.Bounds | null;
  selectedTime: Date | null | undefined;
  compareViewsCount: number;
  pinnedLocation: number[] | null;
};

// calculate initial values by merging with values from local storage
const stateFromLocalStorage = getLayerStateFromLocalStorage();
const selectedInitialBoundary =
  process.env.REACT_APP_ID === 'FireDangerViewer'
    ? stateFromLocalStorage?.selectedBoundary ?? 'Jurisdiction'
    : 'FireManagementDistricts';
const selectedInitialTime = stateFromLocalStorage?.selectedTimeString
  ? new Date(stateFromLocalStorage?.selectedTimeString)
  : new Date();

const initialState: LayerState = {
  layers: {
    status: 'idle',
  },
  legends: LayerManager.layerIds.reduce<Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>>(
    (obj, id) => {
      // eslint-disable-next-line no-param-reassign
      obj[id] = { status: 'idle' };
      return obj;
    },
    {} as Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>,
  ),
  bookmarkedLayers: {
    status: 'idle',
  },
  bookmarkUpdate: {
    status: 'idle',
  },
  selectedLayers: stateFromLocalStorage?.selectedLayers ?? ['Incidents'],
  selectedMainLayer: stateFromLocalStorage?.selectedMainLayer ?? 'IDZ10136_AUS_AFDRS_max_fdr_SFC',
  selectedCompareLayers: stateFromLocalStorage?.selectedCompareLayers ?? [
    'IDZ10136_AUS_AFDRS_max_fdr_SFC',
    'IDZ10134_AUS_AFDRS_fdr_SFC',
    'IDZ71018_AUS_RH_SFC',
    'IDZ71071_AUS_WindMagKmh_SFC',
  ],
  selectedBaseMap: stateFromLocalStorage?.selectedBaseMap ?? 'OSM',
  selectedBoundary: selectedInitialBoundary,
  viewBounds: stateFromLocalStorage?.viewBounds ?? null,
  selectedTime: selectedInitialTime,
  compareViewsCount: stateFromLocalStorage?.compareViewsCount ?? 2,
  pinnedLocation: stateFromLocalStorage?.pinnedLocation ?? null,
};

export const setViewBounds = createAction<Common.Bounds | null>('layers/viewbounds/set');

export const setSelectedComparePageMainLayer = createAction<{
  index: number;
  id: LayerManager.Layer.LayerIds | null;
}>('layers/selected/comparelayer');

export const setSelectedMainLayer = createAction<LayerManager.Layer.LayerIds | null>('layers/selected/mainlayer');

export const setBasemapLayer = createAction<LayerManager.Layer.LayerIds | null>('layers/selected/basemap');

export const setBoundaryLayer = createAction<LayerManager.Layer.LayerIds | null>('layers/selected/boundary');

export const setSelectedLayers = createAction<LayerManager.Layer.LayerIds[] | null>('layers/selected/layers');

export const setSelectedTime = createAction<Date | null | undefined>('layers/selected/time');

export const setCompareViewsCount = createAction<number>('layers/selected/viewsCount');

export const setPinnedLocation = createAction<number[] | null>('layers/selected/pinnedLocation');

export const [getLayers, setLayers] = makeThunkFromAPICall(LayerManager.getLayers, 'layers/set');

export const [getBookmarkedLayers, setBookmarks] = makeThunkFromAPICall(
  LayerManager.getBookmarkedLayers,
  'layers/bookmarks/set',
  false,
);

export const [addBookmark, updateBookmarks] = makeThunkFromAPICall(
  LayerManager.addBookmark,
  'layers/bookmarks/update',
  false,
);

export const [removeBookmark, setRemoveBookmarks] = makeThunkFromAPICall(
  LayerManager.removeBookmark,
  'layers/bookmarks/remove',
  false,
);

export const [clearBookmarks, setClearBookmarks] = makeThunkFromAPICall(
  LayerManager.clearBookmarks,
  'layers/bookmarks/clear',
  false,
);

export const [getLegend, setLegend] = makeThunkFromAPICall(WmsManager.getLegend, 'layers/legend/set');

export const [getLegendImage, setLegendImage] = makeThunkFromAPICall(
  WmsManager.getLegendImage,
  'layers/legendImage/set',
);

export const [queryWMSImage, setWMSQueryImage] = makeThunkFromAPICall(
  WmsManager.queryWMSImage,
  'layers/wmsQueryImage/set',
);

export const layerReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLayers, (state, action) => {
    return { ...state, layers: { ...state.layers, ...action.payload.loadable } };
  });

  builder.addCase(setBookmarks, (state, action) => {
    return { ...state, bookmarkedLayers: { ...state.bookmarkedLayers, ...action.payload.loadable } };
  });

  builder.addCase(updateBookmarks, (state, action) => {
    return { ...state, bookmarkUpdate: { ...state.bookmarkUpdate, ...action.payload.loadable } };
  });

  builder.addCase(setRemoveBookmarks, (state, action) => {
    return { ...state, bookmarkUpdate: { ...state.bookmarkUpdate, ...action.payload.loadable } };
  });

  builder.addCase(setClearBookmarks, (state, action) => {
    return { ...state, bookmarkUpdate: { ...state.bookmarkUpdate, ...action.payload.loadable } };
  });

  builder.addCase(setSelectedComparePageMainLayer, (state, action) => {
    const newList = [...state.selectedCompareLayers];
    newList[action.payload.index] = action.payload.id;
    return { ...state, selectedCompareLayers: newList };
  });

  builder.addCase(setSelectedMainLayer, (state, action) => {
    return { ...state, selectedMainLayer: action.payload };
  });

  builder.addCase(setBasemapLayer, (state, action) => {
    return { ...state, selectedBaseMap: action.payload };
  });

  builder.addCase(setBoundaryLayer, (state, action) => {
    return { ...state, selectedBoundary: action.payload };
  });

  builder.addCase(setSelectedLayers, (state, action) => {
    return { ...state, selectedLayers: action.payload };
  });

  builder.addCase(setLegend, (state, action) => {
    return {
      ...state,
      legends: {
        ...state.legends,
        [action.payload.input.layer.id]: {
          ...state.legends[action.payload.input.layer.id],
          ...action.payload.loadable,
        },
      },
    };
  });

  builder.addCase(setViewBounds, (state, action) => {
    return { ...state, viewBounds: action.payload };
  });

  builder.addCase(setSelectedTime, (state, action) => {
    return { ...state, selectedTime: action.payload };
  });

  builder.addCase(setCompareViewsCount, (state, action) => {
    return { ...state, compareViewsCount: action.payload };
  });

  builder.addCase(setPinnedLocation, (state, action) => {
    return { ...state, pinnedLocation: action.payload };
  });
});

export const LayerActions = {
  setViewBounds,
  setSelectedComparePageMainLayer,
  setSelectedMainLayer,
  setBasemapLayer,
  setBoundaryLayer,
  setSelectedLayers,
  getLayers,
  getBookmarkedLayers,
  addBookmark,
  removeBookmark,
  clearBookmarks,
  getLegend,
  setLegend,
  getLegendImage,
  queryWMSImage,
  setSelectedTime,
  setCompareViewsCount,
  setPinnedLocation,
};

export default layerReducer;

const config = {
  api_url: process.env.REACT_APP_API_URL || 'https://api.dev.fse.afdrs.org.au',
  geoserver_url: process.env.REACT_APP_GEOSERVER_URL
    ? `${process.env.REACT_APP_GEOSERVER_URL}/geoserver`
    : `https://geoserver1.dev.fse.afdrs.org.au/geoserver`,
  cognito_url: process.env.REACT_APP_COGNITO_URL || 'https://auth.dev.fse.afdrs.org.au',
  client_id: process.env.REACT_APP_CLIENT_ID || '',
  enable_reporting: (process.env.REACT_APP_ENABLE_REPORTING || '').toLowerCase() === 'true',

  // The localstorage key for storing OAuth2 Token
  localstorage_auth: 'auth',
  loginRetryKey: 'loginRetryCounter',
  bookmarkedLayersKey: 'bookmarks',
  layerState: 'layerState',
  localExtents: 'localExtents',
  jurisdictionBounds: {
    aus: {
      minLong: 110,
      minLat: -44.5,
      maxLong: 155,
      maxLat: -8.75,
    },
    nsw: {
      minLong: 140.59421,
      minLat: -37.554757,
      maxLong: 156.188543,
      maxLat: -28.168711,
    },
    act: {
      minLong: 148.673094,
      minLat: -35.95373,
      maxLong: 149.482184,
      maxLat: -35.096374,
    },
    vic: {
      minLong: 140.328154,
      minLat: -39.05179,
      maxLong: 151.508141,
      maxLat: -33.494182,
    },
    qld: {
      minLong: 137.389543,
      minLat: -29.272584,
      maxLong: 154.833952,
      maxLat: -9.079555,
    },
    sa: {
      minLong: 128.061655,
      minLat: -38.449224,
      maxLong: 141.553767,
      maxLat: -25.759305,
    },
    nt: {
      minLong: 128.729048,
      minLat: -26.320283,
      maxLong: 138.286582,
      maxLat: -10.495004,
    },
    wa: {
      minLong: 110.355516,
      minLat: -36.116667,
      maxLong: 129.68718,
      maxLat: -12.393048,
    },
    tas: {
      minLong: 143.309389,
      minLat: -43.849211,
      maxLong: 149.04034,
      maxLat: -39.305341,
    },
  },
};

export default config;

/* eslint-disable no-param-reassign */
import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Home } from '@material-ui/icons';

import config from 'config';
import { RootState } from 'store';
import { getGeoserverEndpoint, getProperty, getTileGrid, getUserState } from 'utils';
import { LayerManager, LiteralUnion } from 'models';
import { MapCommands } from 'components';
import { customColors } from 'theme';

import 'ol/ol.css';
import './Map.css';

import { Map as OLMap, View, Collection, Feature, Overlay } from 'ol';
import { MapOptions } from 'ol/PluggableMap';
import TileLayer from 'ol/layer/Tile';
import { Vector as VectorLayer } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import OSM from 'ol/source/OSM';
import { TileWMS, Vector as VectorSource } from 'ol/source';
import { fromLonLat, transform, transformExtent } from 'ol/proj';
import { Point, Circle } from 'ol/geom';
import { Style, Stroke, Fill, Circle as CircleStyle } from 'ol/style';
import ZoomControl from 'ol/control/Zoom';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import { ViewOptions } from 'ol/View';
import { Options as OverlayOptions } from 'ol/Overlay';
import TileState from 'ol/TileState';

const styles = () =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      flex: 1,
    },
    overlay: {
      position: 'absolute',
      top: '0%',
      border: 'solid transparent',
      height: '100%',
      width: '100%',
      display: 'contents',
    },
    olPopup: {
      position: 'absolute',
      backgroundColor: customColors.white,
      boxShadow: '0 1px 4px rgba(0,0,0,0.2)',
      padding: 15,
      borderRadius: 10,
      border: '1px solid #cccccc',
      bottom: 12,
      left: -50,
      minWidth: 280,
      '&::after': {
        borderTopColor: customColors.white,
        borderWidth: 10,
        left: 48,
        marginLeft: -10,
      },
      '&::before': {
        top: '100%',
        border: 'solid transparent',
        content: '" "',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderTopColor: customColors.white,
        borderWidth: 11,
        left: 48,
        marginLeft: -11,
      },
    },
    olPopupCloser: {
      textDecoration: 'none',
      position: 'absolute',
      top: 2,
      right: 8,
      cursor: 'pointer',
      '&::after': {
        content: '"✖"',
      },
    },
  });

const mapStateToProps = (state: any): Map.StateProps => {
  return { ...state };
};

/* (dispatch: ThunkDispatch<any, any, any>, ownProps: Map.OwnProps,) : Map.DispatchProps */
const mapDispatchToProps = (): Map.DispatchProps => ({});

export declare namespace Map {
  export interface FeatureLayer {
    dataSource: (string | number)[];
    toFeatures: (items: any[]) => Feature[];
    visible?: boolean;
    opacity?: number;
    style?: Style;
    id: string;
  }

  export interface CustomOverlay extends OverlayOptions {
    jsx?: JSX.Element;
  }

  type BaseMapIdType = LiteralUnion<'OSM' | 'NationalBaseMap' | 'World_Bathymetry_Imagery'> | null;

  /**
   * To Add:
   * - mapLayers: for time enabled imagery layers
   * - wmsServices: a list of wms endpoints + some settings
   * - wfsServices: a list of wfs endpoints + settings (maybe merged into wms and renamed)
   */
  export interface MapProps extends PropsWithChildren {
    target?: HTMLElement | HTMLDivElement | string;
    basemap?: BaseMapIdType;
    featureLayers?: FeatureLayer[];
    view?: View;
    showUser?: boolean;
    style?: React.CSSProperties;
    overlayStyle?: React.CSSProperties;
    overlayClassName?: string;
    shouldDisplay?: boolean;
    customOverlays?: CustomOverlay[];
    mapRef?: React.RefObject<any>;
    hideStandardPopup?: boolean;
    registerMapCommand?: (register: (command: MapCommands.MapCommand) => void) => void;
  }

  /**
   * Map's internal state
   */
  export interface State {}

  /**
   * Map's Properties (extends Styles passed to it)
   */
  export interface OwnProps extends WithStyles<typeof styles>, MapProps {}

  /**
   * Map's Properties that matches Redux Dispatch actions
   */
  export interface DispatchProps {}

  /**
   * Map's Properties that are set via the Redux global app state
   */
  export interface StateProps extends RootState {}

  export type Props = StateProps & OwnProps & DispatchProps;
}

const getUserPositionFeatures = (position: GeolocationPosition) => {
  // The users location as a point
  const userLocationPoint = new Point(
    transform([position.coords.longitude, position.coords.latitude], 'EPSG:4326', 'EPSG:3857'),
  );

  const userCenterPointFeature = new Feature({
    geometry: userLocationPoint,
  });

  userCenterPointFeature.setStyle(
    new Style({
      geometry: userLocationPoint,
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: [19, 104, 216],
        }),
      }),
    }),
  );

  // A circle showing accuracy
  const userAccuracyFeature = new Feature({
    geometry: new Circle(
      transform([position.coords.longitude, position.coords.latitude], 'EPSG:4326', 'EPSG:3857'),
      position.coords.accuracy,
    ),
  });

  userAccuracyFeature.setStyle(
    new Style({
      stroke: new Stroke({
        color: [19, 104, 216],
        width: 2,
      }),
    }),
  );

  return [userCenterPointFeature, userAccuracyFeature];
};

class Map extends React.Component<Map.Props, Map.State> {
  map?: OLMap;

  basemapLayer: BaseLayer | null = null;

  mapDiv: React.RefObject<HTMLDivElement>;

  defaultOptions: Partial<Map.Props> = {
    view: new View({ center: fromLonLat([130, -28]), zoom: 5 }),
    showUser: false,
    shouldDisplay: true,
  };

  constructor(props: Map.Props, context?: any) {
    super(props, context);
    this.state = { isAuthReady: false };

    this.mapDiv = props.mapRef ?? React.createRef<HTMLDivElement>();

    this.mapOLPropsToOptions = this.mapOLPropsToOptions.bind(this);
  }

  componentDidMount() {
    // need to wait for auth to be ready to set the jurisdiction
    this.waitForAuthReady().then(() => {
      const options: MapOptions = this.mapOLPropsToOptions(this.props);

      this.map = new OLMap(options);

      const { registerMapCommand } = this.props;

      if (registerMapCommand) {
        registerMapCommand(this.handleMapCommand);
      }
    });
  }

  waitForAuthReady() {
    return new Promise<void>((resolve) => {
      const checkAuthInterval = setInterval(() => {
        if (this.props.auth && this.props.auth.status === 'finished') {
          clearInterval(checkAuthInterval);
          resolve();
        }
      }, 100);
    });
  }

  componentDidUpdate(prevProps: Map.Props) {
    const { view, featureLayers, showUser, position, shouldDisplay, basemap } = this.props;

    if (shouldDisplay !== prevProps.shouldDisplay) this.map?.updateSize();

    const newViewSettings: ViewOptions = {
      center: view?.getCenter(),
      zoom: view?.getZoom(),
    };
    let viewChanged = false;
    const prevCenter = prevProps.view?.getCenter();
    const currCenter = view?.getCenter();
    if (currCenter && prevCenter && (prevCenter[0] !== currCenter[0] || prevCenter[1] !== currCenter[1])) {
      viewChanged = true;
    }
    if (view && prevProps.view?.getZoom() !== view.getZoom()) {
      viewChanged = true;
    }

    if (viewChanged) this.map?.getView().animate(newViewSettings);

    // props.position.object ? getUserPositionFeatures(props.position.object) : []
    if (showUser && position.object && prevProps.position.object) {
      const prevCoords = prevProps.position.object.coords;
      const currCoords = position.object.coords;
      if (
        currCoords.latitude !== prevCoords.latitude ||
        currCoords.longitude !== prevCoords.longitude ||
        currCoords.accuracy !== prevCoords.accuracy
      ) {
        const userPositionLayer =
          this.map &&
          (this.map
            .getLayers()
            .getArray()
            .find((vl) => vl.getProperties().id === 'user_position_layer') as VectorLayer<VectorSource>);
        if (userPositionLayer) {
          const featureCollection = userPositionLayer.getSource()?.getFeaturesCollection();
          featureCollection?.clear();
          featureCollection?.extend(getUserPositionFeatures(position.object));
          userPositionLayer.setVisible(true);
        }
      }
    }

    if (featureLayers) {
      featureLayers.forEach((layer) => {
        const currData = getProperty(layer.dataSource, this.props);
        const prevData = getProperty(layer.dataSource, prevProps);
        if (currData && prevData && JSON.stringify(currData) !== JSON.stringify(prevData)) {
          const mapLayer =
            this.map &&
            (this.map
              .getLayers()
              .getArray()
              .find((vl) => vl.getProperties().id === layer.id) as VectorLayer<VectorSource>);
          if (mapLayer) {
            const featureCollection = mapLayer.getSource()?.getFeaturesCollection();
            featureCollection?.clear();
            featureCollection?.extend(layer.toFeatures(currData));
          }
        }
      });
    }

    if (prevProps.basemap !== basemap) {
      this.removeBaseMap();
      if (basemap) {
        const bmap = this.generateBaseMap(basemap);
        if (bmap) this.map?.addLayer(bmap);
      }
    }
  }

  componentWillUnmount() {
    this.map?.setTarget(undefined);
  }

  handleMapCommand = (command: MapCommands.MapCommand) => {
    if (!this.map) return;
    if (!command) return;
    command.apply({
      map: this.map,
    });
  };

  closePopup = () => {
    this.map?.getOverlayById('popup').setPosition(undefined);
  };

  generateBaseMap = (basemap: Map.BaseMapIdType): BaseLayer | null => {
    if (basemap == null) return null;

    const layer = LayerManager.findLayer(basemap, LayerManager.layerData);

    if (layer == null) return null;

    const { auth } = this.props;

    if (basemap === 'OSM') {
      this.basemapLayer = new TileLayer({
        source: new OSM(),
        zIndex: -1,
      });
    } else if (basemap === 'NationalBaseMap' || basemap === 'World_Bathymetry_Imagery') {
      const source = new TileWMS({
        crossOrigin: 'anonymous',
        url: getGeoserverEndpoint(),
        params: {
          LAYERS: layer.serviceName,
          VERSION: '1.1.1',
          TILED: true,
          SRS: 'EPSG:3857',
        },
        // NationalBaseMap supports 16 levels, Bathymetry supports 11
        tileGrid: getTileGrid('EPSG:3857', null, basemap === 'NationalBaseMap' ? 16 : 11),
      });

      source.setTileLoadFunction((tile, src) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        // Need it like this for the 'this' variable to work
        // eslint-disable-next-line func-names
        xhr.addEventListener('loadend', function () {
          const data = this.response;
          if (data !== undefined) {
            try {
              // @ts-ignore
              tile.getImage().src = URL.createObjectURL(data);
            } catch (e) {
              console.error(e);
              tile.setState(TileState.ERROR);
            }
          } else {
            tile.setState(TileState.ERROR);
          }
        });
        xhr.addEventListener('error', () => {
          tile.setState(TileState.ERROR);
        });
        xhr.open('GET', src);

        if (auth?.object?.creds)
          xhr.setRequestHeader('Authorization', `${auth.object.creds.token_type} ${auth.object.creds.access_token}`);

        xhr.send();
      });
      this.basemapLayer = new TileLayer({
        source,
        zIndex: -1,
      });
    }

    return this.basemapLayer;
  };

  removeBaseMap = () => {
    if (this.basemapLayer) this.map?.removeLayer(this.basemapLayer);
  };

  mapOLPropsToOptions(inProps: Map.Props): MapOptions {
    const overlay = new Overlay({
      id: 'popup',
      element: document.getElementById('popup') || undefined, // this.popupRef.current || undefined,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const props = {
      ...this.defaultOptions,
      target: this.mapDiv.current,
      ...inProps,
    };

    const layers = [];
    // Handle loading basemap at the bottom
    if (props.basemap) {
      const basemap = this.generateBaseMap(props.basemap);
      if (basemap) layers.push(basemap);
    }

    // Handle loading feature layers
    if (props.featureLayers) {
      const featureLayers = props.featureLayers.reduce<BaseLayer[]>((acc, featureLayer) => {
        const data = getProperty(featureLayer.dataSource, this.props);

        const vl = new VectorLayer({
          source: new VectorSource({
            features: new Collection(data ? featureLayer.toFeatures(data) : []),
          }),
          style: featureLayer.style,
          opacity: featureLayer.opacity,
          visible: featureLayer.visible,
        });
        vl.setProperties({ id: featureLayer.id });
        acc.push(vl);

        return acc;
      }, []);
      layers.push(...featureLayers);
    }

    if (props.showUser) {
      const userLocationLayer = new VectorLayer({
        source: new VectorSource({
          features: new Collection(props.position.object ? getUserPositionFeatures(props.position.object) : []),
        }),
        opacity: 0.9,
        visible: props.position.object != null,
      });
      userLocationLayer.setProperties({ title: 'My Location', id: 'user_position_layer' });
      layers.push(userLocationLayer);
    }

    const customOverlays =
      props.customOverlays?.map((customOverlay) => {
        return new Overlay({
          ...customOverlay,
          element: document.getElementById(`${customOverlay.id}`) || undefined,
        });
      }) ?? [];

    const zoomControl = new ZoomControl();

    // define the extent of the current jurisdiction and set that to
    // zoom to extent control
    type Extent = [number, number, number, number];

    const jd = getUserState(props.auth.object);
    const jb = jd ? config.jurisdictionBounds[jd] : undefined;

    const extent = jb ? ([jb.minLong, jb.maxLat, jb.maxLong, jb.minLat] as Extent) : undefined;

    const convertedExtent = extent ? (transformExtent(extent, 'EPSG:4326', 'EPSG:3857') as Extent) : undefined;

    const createHTMLElementFromReactComponent = (component: React.ReactElement) => {
      const componentString = ReactDOMServer.renderToStaticMarkup(component);
      const div = document.createElement('div');
      div.innerHTML = componentString.trim();
      const element = div.firstElementChild;
      return element ? (element as HTMLElement) : undefined;
    };

    const zoomExtentControl = new ZoomToExtent({
      extent: convertedExtent,
      label: createHTMLElementFromReactComponent(<Home />),
      tipLabel: 'Zoom to Jurisdiction',
    });

    return {
      view: props.view,
      target: props.target ?? undefined,
      layers,
      controls: [zoomControl, zoomExtentControl],
      overlays: [overlay, ...customOverlays],
    };
  }

  render() {
    const {
      classes,
      children,
      style,
      overlayStyle,
      overlayClassName,
      shouldDisplay,
      customOverlays,
      hideStandardPopup,
    } = this.props;
    return (
      <>
        <div
          className={classes.root}
          style={{ display: shouldDisplay ? 'block' : 'none', ...style }}
          ref={this.mapDiv}
        />
        {!hideStandardPopup && (
          <div className={classes.olPopup} id="popup">
            <span
              className={classes.olPopupCloser}
              onClick={this.closePopup}
              role="button"
              onKeyDown={this.closePopup}
              tabIndex={0}
              aria-label="Close"
            />
            <div className="popup-content" />
          </div>
        )}
        {customOverlays?.map((customOverlay) => (
          <div id={`${customOverlay.id}`} key={`${customOverlay.id}`}>
            {customOverlay.jsx}
          </div>
        ))}
        <div
          className={`${classes.overlay} ${overlayClassName || ''}`}
          style={{ display: shouldDisplay ? 'contents' : 'none', ...overlayStyle }}
        >
          {children}
        </div>
      </>
    );
  }
}

// @ts-ignore
Map.displayName = 'Map';

// Apply Decorators
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Map));

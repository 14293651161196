import React, { useState, useEffect, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Card, CardContent, Typography, Divider } from '@material-ui/core';
import { Close, SvgIconComponent, ExpandMore, ExpandLess } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 175,
    maxWidth: 340,
  },
  header: {
    position: 'relative',
    padding: 3,
    paddingBottom: '3px !important',
  },
  title: {
    color: theme.palette.common.neutralDark,
    fontSize: 16,
  },
  closeButton: {
    textDecoration: 'underline',
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 3,
    fontSize: 16,
    color: theme.palette.common.neutralDark,
    '&:hover': { color: 'black' },
    outline: 0,
  },
}));

interface Props extends PropsWithChildren {
  title: string;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClose?: () => void;
  reverseExpandDirection?: boolean;
  canBeOpen?: boolean;
}

const renderIcon = (Icon: SvgIconComponent) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Icon />
  </div>
);

const OpenCloseButton: React.FunctionComponent<{
  onClick: () => void;
  isOpen: boolean;
  reverseExpandDirection?: boolean;
}> = ({ onClick, isOpen, reverseExpandDirection }) => {
  const classes = useStyles();
  const expandIcon = reverseExpandDirection ? ExpandLess : ExpandMore;
  return (
    <span className={classes.closeButton} onClick={onClick} role="button" onKeyDown={onClick} tabIndex={0}>
      {isOpen ? renderIcon(Close) : renderIcon(expandIcon)}
    </span>
  );
};

const CloseableCard: React.FunctionComponent<Props> = ({
  title,
  children,
  contentStyle,
  style,
  onClose,
  reverseExpandDirection,
  canBeOpen = true, // Default to true if not provided
}) => {
  const classes = useStyles();

  const [openValue, setOpen] = useState(false);

  useEffect(() => {
    // Close the card if canBeOpen becomes false
    if (!canBeOpen && openValue) {
      setOpen(false);
      if (onClose) onClose(); // Trigger onClose if applicable
    }
  }, [canBeOpen, openValue, onClose]);

  return (
    <Card className={classes.root} style={style}>
      <CardContent className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <OpenCloseButton
          onClick={() => {
            if (openValue && onClose) {
              onClose();
            }
            setOpen(!openValue);
          }}
          isOpen={openValue}
          reverseExpandDirection={reverseExpandDirection}
        />
      </CardContent>
      {openValue && (
        <>
          <Divider />
          <CardContent style={contentStyle}>{children}</CardContent>
        </>
      )}
    </Card>
  );
};

CloseableCard.displayName = 'CloseableCard';
export default CloseableCard;

import { Middleware } from '@reduxjs/toolkit';
import config from 'config';
import { Common } from 'models';
import {
  LayerState,
  setBasemapLayer,
  setBoundaryLayer,
  setSelectedComparePageMainLayer,
  setSelectedLayers,
  setSelectedMainLayer,
  setSelectedTime,
  setViewBounds,
  setCompareViewsCount,
  setPinnedLocation,
} from 'state/layers';
import { RootState } from 'store';

type Bounds = Common.Bounds;

// omit anything in LayerState that shouldn't be persisted in localStorage
type OmittedLayerState = Omit<
  LayerState,
  'bookmarkUpdate' | 'bookmarkedLayers' | 'legends' | 'layers' | 'selectedTime'
>;
type LocalStorageLayerState = {
  // selectedTime can't be serialized as a Date. It is being stored as a string instead.
  selectedTimeString: string | null | undefined;
} & OmittedLayerState;

// middleware for persisting layer state beyond browser tab lifespan
export const layerListenerMiddleware: Middleware<
  // eslint-disable-next-line @typescript-eslint/ban-types -- https://redux.js.org/usage/usage-with-typescript#type-checking-middleware
  {},
  RootState
> =
  ({ getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      // handle all the 'setSelected' map actions by copying the non-loadable layers state to local storage
      // same code per action so all the cases can fall through
      case setViewBounds.type:
      case setSelectedMainLayer.type:
      case setSelectedLayers.type:
      case setSelectedComparePageMainLayer.type:
      case setBasemapLayer.type:
      case setBoundaryLayer.type:
      case setSelectedTime.type:
      case setCompareViewsCount.type:
      case setPinnedLocation.type:
        setTimeout(() => {
          try {
            // setTimeout simulates an async action to get state
            // otherwise getState() simply returns initial state
            // https://stackoverflow.com/questions/51384931/accessing-state-in-middleware-in-redux
            const layers = getState().layers;
            const omittedLayerState: LocalStorageLayerState = {
              selectedLayers: layers.selectedLayers,
              selectedMainLayer: layers.selectedMainLayer,
              selectedCompareLayers: layers.selectedCompareLayers,
              selectedBaseMap: layers.selectedBaseMap,
              selectedBoundary: layers.selectedBoundary,
              viewBounds: layers.viewBounds,
              // ISO format seems to be the most guaranteed way to reliably read/write the date string
              selectedTimeString: layers.selectedTime?.toISOString(),
              compareViewsCount: layers.compareViewsCount,
              pinnedLocation: layers.pinnedLocation,
            };

            localStorage.setItem(config.layerState, JSON.stringify(omittedLayerState));
          } catch (error) {
            console.error('Error saving layer state to localStorage:', error);
          }
        }, 0);

        next(action);
        break;
      default:
        next(action);
        break;
    }
  };

export const setExtentToLocalStorage = (name: string, extent: Bounds, date: string): void => {
  const configKey = config.localExtents;
  const localExtents = localStorage.getItem(configKey);

  let extentsArray: { name: string; extent: Bounds; date: string }[] = [];

  if (localExtents) {
    try {
      extentsArray = JSON.parse(localExtents);
    } catch (error) {
      console.error('Failed to parse local extents:', error);
      extentsArray = [];
    }
  }

  // Add the new extent
  extentsArray.push({ name, extent, date });

  // Save the updated array back to local storage
  localStorage.setItem(configKey, JSON.stringify(extentsArray));
};

export const getExtentsFromLocalStorage = (): { name: string; extent: Bounds; date: string }[] => {
  const localExtents = localStorage.getItem(config.localExtents);

  if (localExtents) {
    try {
      return JSON.parse(localExtents);
    } catch (error) {
      console.error('Failed to parse local extents:', error);
    }
  }

  return [];
};

/** Retrieves MapSettings from local storage.
 * It parses a json string into a MapSettings object and returns the object.
 * This should only be needed when redux store is initialised,
 * as we are only using localStorage to persist between browser tabs rather than replacing redux.
 */
export const getLayerStateFromLocalStorage = (): LocalStorageLayerState | null => {
  const mapSettingsUnserialised = localStorage.getItem(config.layerState);
  const mapSettings = mapSettingsUnserialised ? (JSON.parse(mapSettingsUnserialised) as LocalStorageLayerState) : null;
  return mapSettings;
};

import React from 'react';
import { makeStyles, createStyles, lighten } from '@material-ui/core/styles';
import { Theme, Paper, Typography, TextField } from '@material-ui/core';
import { Edit, Close, Save } from '@material-ui/icons';
import { toTimeDDMonYearFormat } from 'utils';
import config from 'config';
import { Common } from 'models';
import { MapCommand, ZoomToBounds } from 'components/map/MapCommands';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { minWidth: 100, marginBottom: theme.spacing(1) },
    parentButton: {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: lighten(theme.palette.common.neutralLight, 0.8),
      },
      cursor: 'pointer',
    },
    listItemMain: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto auto 1fr auto',
      justifyItems: 'start',
      alignItems: 'center',
      padding: theme.spacing(1),
    },
    closeIcon: {
      cursor: 'pointer',
      color: theme.palette.common.neutral,
      float: 'right',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(3.5),
    },
    addIcon: {
      cursor: 'pointer',
      color: theme.palette.common.neutral,
      float: 'right',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(3.5),
    },
    editIcons: {
      cursor: 'pointer',
      color: theme.palette.common.neutral,
      marginLeft: theme.spacing(0.5),
    },
    dateSection: {
      padding: theme.spacing(1),
    },
    dateText: {
      color: theme.palette.common.neutralDark,
    },
    errorText: {
      color: theme.palette.common.red,
    },
    searchField: {
      padding: '0 !important',
      '& .MuiOutlinedInput-root': {
        '&.error fieldset': {
          borderColor: 'red', // Error state border color
        },
      },
    },
  }),
);

type Bounds = Common.Bounds;

interface ExtentBookmarkProps {
  name: string;
  bounds: Bounds | null;
  date: string;
  storedExtents: { name: string; extent: Bounds; date: string }[];
  setStoredExtents: React.Dispatch<React.SetStateAction<{ name: string; extent: Bounds; date: string }[]>>;
  mapDispatch?: {
    dispatch: (command: MapCommand) => void;
  };
}

const ExtentBookmark: React.FC<ExtentBookmarkProps> = ({
  name,
  date,
  storedExtents,
  setStoredExtents,
  mapDispatch,
}) => {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);
  const [editedName, setEditedName] = React.useState(name);
  const [editingError, setEditingError] = React.useState(false);

  const handleClose = (bookmarkName: string) => {
    const updatedExtents = storedExtents.filter(
      (extent: { name: string; extent: Bounds; date: string }) => extent.name !== bookmarkName,
    );
    localStorage.setItem(config.localExtents, JSON.stringify(updatedExtents));
    setStoredExtents(updatedExtents); // update the persisting state extents
  };

  const handleSetExtent = (bookmarkName: string) => {
    const chosenExtent = storedExtents.filter(
      (extent: { name: string; extent: Bounds; date: string }) => extent.name == bookmarkName,
    );
    if (mapDispatch) mapDispatch.dispatch(new ZoomToBounds(chosenExtent[0].extent));
  };

  const handleSaveEdit = () => {
    const trimmedName = editedName.trim();

    if (!trimmedName) {
      setEditingError(true); // Name cannot be empty
      return;
    }

    const isUnique = !storedExtents.some((extent: { name: string }) => extent.name === trimmedName);

    if (isUnique) {
      const updatedExtents = storedExtents.map((extent: { name: string; extent: Bounds; date: string }) =>
        extent.name === name ? { ...extent, name: trimmedName } : extent,
      );

      localStorage.setItem(config.localExtents, JSON.stringify(updatedExtents));
      setStoredExtents(updatedExtents);
      setIsEditing(false);
      setEditingError(false);
    } else {
      setEditingError(true); // Name is not unique
    }
  };

  const handleCancelEdit = () => {
    setEditedName(name); // Revert to original name
    setIsEditing(false);
    setEditingError(false);
  };

  return (
    <Paper className={classes.root}>
      <div onClick={() => handleSetExtent(name)} className={classes.parentButton}>
        {!isEditing ? (
          <>
            <Close
              className={classes.closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleClose(name);
              }}
            />
            <Edit
              className={classes.addIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
                setEditingError(false);
              }}
            />
          </>
        ) : null}
        <div className={classes.listItemMain}>
          {isEditing ? (
            <>
              <TextField
                variant="outlined"
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                error={editingError}
                className={classes.searchField}
                //className={`${classes.searchField} ${editingError ? 'error' : ''}`}
              />
              <Save
                className={classes.editIcons}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveEdit();
                }}
              />
              <Close
                className={classes.editIcons}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancelEdit();
                }}
              />
            </>
          ) : (
            <Typography>{name}</Typography>
          )}
        </div>
        <div className={classes.dateSection}>
          {!editingError ? (
            <Typography className={classes.dateText} variant="subtitle2">
              Date added: {toTimeDDMonYearFormat(new Date(date))}
            </Typography>
          ) : (
            <Typography className={classes.errorText} variant="subtitle2" style={{ color: 'red' }}>
              Name already exists or is empty
            </Typography>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default ExtentBookmark;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';

// @ts-ignore no types available, follow this for Doco https://github.com/shahid28/utm-latlng/blob/master/UTMLatLng.js
import UTMLatLng from 'utm-latlng';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  Link,
  Button,
} from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerManager, WmsManager, FuelTypeModelsManager } from 'models';
import { LayerActions } from 'state/layers';
import { ratingToName, toTimeHHMMFormat } from 'utils';

import BigChart from './BigChart';
import IndicatorIcon from './IndicatorIcon';

const utm = new UTMLatLng();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padded: {
      padding: theme.spacing(2),
    },
    section: {
      borderRight: `1px solid ${theme.palette.common.neutralLight}`,
      padding: theme.spacing(2),
    },
    lastSection: {
      flexGrow: 1,
      padding: theme.spacing(2),
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    },
    heading: {
      color: theme.palette.common.neutralDark,
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    description: {
      fontSize: 14,
      color: '#4F4F4F',
    },
    indicatorTitle: {
      fontWeight: 500,
      fontSize: 18,
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tableHeading: {
      fontWeight: 600,
      fontSize: 12,
      color: theme.palette.common.neutralDark,
      paddingLeft: 0,
      paddingBottom: 2,
      paddingTop: 2,
    },
    tableCell: {
      fontWeight: 500,
      fontSize: 12,
      color: 'black',
      textAlign: 'right',
      paddingRight: 0,
      paddingBottom: 2,
      paddingTop: 2,
    },
    chartContainer: {
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      display: 'inline-block',
      marginRight: theme.spacing(2),
    },
    chartTitle: {
      fontSize: 12,
      color: theme.palette.common.neutralDark,
    },
  }),
);

/*
 * Based off of an image sent by Stuart so the correct
 * fire danger definition can be linked to from the
 * fuel type models.
 */
const fuelTypeModelToDefinition: Record<string, string> = {
  forest: 'Forest',
  'wet forest': 'Forest',
  pine: 'Pine',
  woodland: 'Savanna',
  'acacia woodland': 'Savanna',
  'gamba grass': 'Savanna',
  'woody horticulture': 'Savanna',
  rural: 'Savanna',
  urban: 'Savanna',
  spinifex: 'Spinifex',
  'spinifex woodland': 'Spinifex',
  mallee: 'Mallee-heath',
  'mallee chenopod': 'Mallee-heath',
  'chenopod shrubland': 'Grass',
  heath: 'Shrubland',
  'wet heath': 'Shrubland',
  buttongrass: 'Buttongrass',
  'low wetland': 'Grass',
  grass: 'Grass',
  grassland: 'Grass',
  pasture: 'Grass',
  crop: 'Grass',
  horticulture: '',
  'non-combustible': '',
  'built up': '',
};

const DetailsTable: FunctionComponent<{
  coords: number[] | null;
  fdr?: number | string | null;
  selectedDate?: Date | null;
  isDailyLayer?: boolean;
}> = ({ coords, selectedDate, fdr, isDailyLayer }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { layers, legends } = useAppSelector((state) => state.layers);
  const { bom } = useAppSelector((state) => state.fuelTypeModels);
  const { meteograms } = useAppSelector((state) => state.meteograms);

  const fuelTypeModel = meteograms.object?.keyFactors?.IDZ10161_AUS_FSE_fuel_type_SFC
    ? FuelTypeModelsManager.getFuelTypeById(meteograms.object.keyFactors.IDZ10161_AUS_FSE_fuel_type_SFC, bom.object)
    : null;
  const definitionFuelType = fuelTypeModel?.FBM ? fuelTypeModelToDefinition[fuelTypeModel.FBM.toLowerCase()] : '';

  useEffect(() => {
    const layer = LayerManager.findLayer('IDZ10134_AUS_AFDRS_fdr_SFC', layers.object);
    if (layer && legends.IDZ10134_AUS_AFDRS_fdr_SFC.status === 'idle') dispatch(LayerActions.getLegend({ layer }));
  }, [layers]);

  const color = fdr != null ? WmsManager.getColour(fdr, legends.IDZ10134_AUS_AFDRS_fdr_SFC.object) : null;

  const utmValues = coords && utm ? utm.convertLatLngToUtm(coords[1], coords[0], 1) : null;

  const data = [
    { key: 'Latitude', value: coords ? coords[1].toFixed(4) : 'N/A' },
    { key: 'Longitude', value: coords ? coords[0].toFixed(4) : 'N/A' },
    {
      key: 'UTM',
      value:
        (coords &&
          Object.keys(utmValues)
            .map((key) => `${utmValues[key]}`)
            .join(' ')) ??
        'N/A',
    },
    { key: 'Fuel type', value: fuelTypeModel?.Fuel_FDR ?? '' },
    { key: 'Model', value: fuelTypeModel?.FBM ?? '' },
    { key: 'Sub-fuel type', value: fuelTypeModel?.Fuel_Name ?? '' },
  ];

  const downloadFuelTypeModels = () => {
    if (bom.object) {
      const csvContent = FuelTypeModelsManager.toCSV(bom.object.data);
      const link = window.document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
      link.setAttribute('download', 'fuel-type-models.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      {isDailyLayer ? (
        <Typography className={classes.heading}>Max Fire danger rating (daily)</Typography>
      ) : (
        <Typography className={classes.heading}>
          Fire danger rating {selectedDate != null ? `(hourly @ ${toTimeHHMMFormat(selectedDate)})` : ''}
        </Typography>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {selectedDate != null ? (
          <div>
            {color && <IndicatorIcon colour={color} />}
            <div className={classes.indicatorTitle}>{fdr != null ? ratingToName(+fdr) : 'N/A'}</div>
          </div>
        ) : (
          <div>
            <div className={classes.indicatorTitle}>N/A</div>
          </div>
        )}
        <div>
          <Link href={`/definitions?fuelType=${definitionFuelType}`} className={classes.description}>
            Understand the Index
          </Link>
        </div>
      </div>
      <Typography className={classes.heading}>Location</Typography>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {data.map((x) => (
              <TableRow key={x.key}>
                <TableCell className={classes.tableHeading}>{x.key}</TableCell>
                <TableCell className={classes.tableCell}>{x.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={downloadFuelTypeModels}
          disabled={!bom.object}
          variant="text"
          style={{
            fontSize: 10,
            padding: '4px 8px 2px 8px',
            marginRight: 8,
          }}
        >
          Download Fuel Type Models as CSV
        </Button>
      </div>
    </>
  );
};

interface MeteogramProps {
  selectedDate: Date | null;
  chartSettings: {
    datasets:
      | {
          data: [Date, number][];
          label: string;
          color: string;
          showLine?: boolean;
          pointRadius?: number;
        }[]
      | undefined;
    units: string;
    name: string;
    yAxesLabelsCallback?: (
      value: string | number,
      index: number,
      values: string[] | number[],
    ) => string | number | null | undefined;
  }[];
}

const Meteograms: FunctionComponent<MeteogramProps> = ({ selectedDate, chartSettings }) => {
  const classes = useStyles();

  return (
    <>
      {chartSettings.map((x) => (
        <div key={x.name} className={classes.chartContainer}>
          <div className={classes.chartTitle}>{x.name}</div>
          <div style={{ height: 200, width: 450 }}>
            <BigChart
              key={x.name}
              name={x.name}
              datasets={x.datasets}
              selectedDate={selectedDate}
              color="black"
              timeFormat="D/M"
              hideLegend
              units={x.units}
              yAxesLabelsCallback={x.yAxesLabelsCallback}
            />
          </div>
        </div>
      ))}
    </>
  );
};

interface IProps extends MeteogramProps {
  coords: number[] | null;
  identifyData?: Record<string, string | number> | null;
  selectedLayer?: LayerManager.Layer.LayerIds | null;
}

const OverviewPanel: FunctionComponent<IProps> = ({
  selectedDate,
  selectedLayer,
  coords,
  chartSettings,
  identifyData,
}) => {
  const classes = useStyles();

  const isDailyLayer = selectedLayer
    ? LayerManager.layerData.find((l) => l.id === selectedLayer)?.hoursPerStep === 24
    : false;

  const fdr = isDailyLayer ? identifyData?.IDZ10136_AUS_AFDRS_max_fdr_SFC : identifyData?.IDZ10134_AUS_AFDRS_fdr_SFC;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className={classes.section} style={{ minWidth: 300 }}>
        <DetailsTable
          coords={coords}
          selectedDate={selectedDate}
          fdr={identifyData != null && fdr != null ? +fdr : undefined}
          isDailyLayer={isDailyLayer}
        />
      </div>
      <div className={classes.lastSection}>
        <Typography className={classes.heading}>Meteograms</Typography>
        <Meteograms selectedDate={selectedDate} chartSettings={chartSettings} />
      </div>
    </div>
  );
};

export default OverviewPanel;
